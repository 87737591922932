import React, {useCallback, useRef } from 'react';
import { Container, Content, AnimationContainer, Background } from './styles';
import { Link, useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import logo from '../../assets/logo.svg'
import Button from '../../components/Button';
import Input from '../../components/Input';



interface SignInFormData{
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn} = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback( async (data: SignInFormData) =>  {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().required('E-mail é obrigatório').email('Digite um e-mail válido'),
        password: Yup.string().required('O campo senha é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await signIn({
        email: data.email,
        password: data.password,
      });

      history.push('/dashboard');

    } catch (err) {
      if(err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao fazer o login.'
      });
    }
  }, [signIn, addToast, history]);

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logo} alt="Logotipo GoBarber" />
            <Form ref={formRef} onSubmit={ handleSubmit } >
              <h1>Faça seu logon</h1>
              <Input name="email" icon={FiMail} type="text" placeholder="E-mail" />
              <Input name="password" icon={FiLock} type="password" placeholder="Senha" />
              <Button name="Login" type="submit">Entrar</Button>
              <Link to="/forgot-password">Esqueci minha senha </Link>
            </Form>
            <Link to='/signup' >
              <FiLogIn />
              Criar Conta
            </Link>
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
    </>
  );
}

export default SignIn;