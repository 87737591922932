import React, { createContext, useCallback, useContext, useState } from 'react';
import ToastContainer from '../components/ToastContainer';
import { uuid } from 'uuidv4';

interface ToastContextData{
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

export interface ToastMessage{
  id: string;
  type: 'info' | 'success' | 'error';
  title: string;
  description: string;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({children}) => {

  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(( message: Omit<ToastMessage, 'id'>) =>{
    const id = uuid();
    const toast = {
      id,
      ...message
    };
    setMessages((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id: string)=>{
    setMessages(state => state.filter((message) => message.id !== id));
  }, []);

  return(
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer  messages={messages} />
    </ToastContext.Provider>
  )
};

function useToast():ToastContextData {
  const context = useContext(ToastContext);

  if(!context) {
    throw new Error('useToast must be used within ToastProvider');
  }
  return context;
}

export { ToastProvider, useToast }