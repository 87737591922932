import React, { useCallback, useRef } from 'react';
import logo from '../../assets/logo.svg'
import { Container, Content,AnimationContainer, Background } from './styles';
import { FiArrowLeft, FiUser, FiMail, FiLock } from 'react-icons/fi';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history  = useHistory();

  const handleSubmit = useCallback( async (data: SignUpFormData) =>  {
    try {

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        email: Yup.string().required('E-mail é obrigatório').email('Digite um e-mail válido'),
        password: Yup.string().min(6, 'Necessário no mínimo 6 digitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api.post('/users', data);

      history.push('/');

      addToast({
        type: 'success',
        title: 'Cadastro realizado.',
        description: 'Você já pode fazer seu logon no GoBarber',
      })

    } catch (err) {
      if(err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Ocorreu um erro ao fazer seu cadastro.'
      });
    }

  }, [addToast, history]);

  return (
    <>
      <Container>
        <Background />
        <Content>
          <AnimationContainer>
            <img src={logo} alt="Logotipo GoBarber" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Faça seu cadastro</h1>
              <Input name="name" icon={FiUser} type="text" placeholder="Nome" />
              <Input name="email" icon={FiMail} type="text" placeholder="E-mail" />
              <Input name="password" icon={FiLock} type="password" placeholder="Senha" />

              <Button type="submit" name="Login">Cadastrar</Button>
            </Form>

            <Link to="/">
              <FiArrowLeft />
              Voltar para logon
            </Link>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  )
};

export default SignUp;